<template>
  <div class="NotFound">
    Oops! The requested page does not exist!
  </div>
</template>

<script>
  export default {
    name: 'Notfound',
  }
</script>
